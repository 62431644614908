import React from 'react';
import Chord from "../classes/Chord";

export default class Circuit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true
        };
    }

    calcNewPosition(curPos, distance, angle) {
        return {
            x: Math.round(Number(curPos.x) + (distance * Math.cos(angle))),
            y: Math.round(Number(curPos.y) + (distance * Math.sin(angle)))
        }
    }

    componentDidMount() {
        let canvas = document.getElementById(this.props.canvas_id);
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.transform(1, 0, 0, -1, 0, canvas.height);

        ctx.strokeStyle = "grey";
        ctx.lineWidth = 1;

        let direction = Math.radians(90);
        let start = {
            x: canvas.width/2,
            y: canvas.height/2
        };
        let pos = {...start}

        console.groupCollapsed('Circuit #' + this.props.canvas_id);

        this.props.shapes.map((shape, i) => {
            const startPos = {...pos};
            switch (shape.type) {
                case 'line':
                    ctx.beginPath();
                    ctx.moveTo(pos.x, pos.y);
                    pos = this.calcNewPosition(pos, shape.line_length, direction);
                    ctx.lineTo(pos.x,pos.y);
                    ctx.stroke();
                    break;
                case 'curve':
                    const origDirection = 0 + direction;
                    direction = direction + Math.radians(shape.curve_angle / 2)


                    pos = this.calcNewPosition(startPos, shape.curve_length, direction);

                    let chord = new Chord(
                        {x1: startPos.x, y1: startPos.y},
                        {x2: pos.x, y2: pos.y}
                    );

                    const radians = Math.radians(shape.curve_angle);
                    const cp = chord.getCenterPoint(radians, origDirection, i);

                    const arcStart = radians > 0 ? origDirection - radians : origDirection;
                    const arcEnd = radians > 0 ? origDirection : origDirection - radians;

                    ctx.beginPath();
                    ctx.arc(cp.x, cp.y, chord.getRadius(radians), arcStart, arcEnd);
                    ctx.stroke();

                    // Center Point
                    // ctx.beginPath();
                    // ctx.arc(cp.x, cp.y, 3, 0, Math.PI*2);
                    // ctx.fill();

                    direction = direction + Math.radians(shape.curve_angle / 2)
                    break;
                case 'chicane':
                    const sideA = shape.chicane_offset;
                    const sideB = shape.chicane_length;
                    const sideC = Math.sqrt(sideA**2 + sideB**2 );
                    const angleA = Math.asin( Math.sin(Math.radians(90)) * sideA/sideC );

                    // const halfOffset = offset == 0 ? 0 : offset/2;

                    ctx.beginPath();
                    ctx.moveTo(startPos.x, startPos.y);
                    pos = this.calcNewPosition(startPos, sideC, direction + angleA);
                    // ctx.lineTo(pos.x,pos.y);
                    ctx.bezierCurveTo(
                        startPos.x,
                        startPos.y + ((pos.y - startPos.y) / 2),
                        pos.x,
                        startPos.y + ((pos.y - startPos.y) / 2),
                        pos.x,
                        pos.y
                    );
                    ctx.stroke();

                    break;
            }
        });

        console.groupEnd('Circuit #' + this.props.canvas_id);

        if (pos.x == start.x && pos.y == start.y) {
            ctx.stroke();
        }
        else {
            this.setState(prevState => {
                return {visible: false};
            });
        }

    }

    render() {
        return (
            <div className="">
                {this.state.visible == true &&
                    <canvas id={this.props.canvas_id} height="320" width="320"></canvas>
                }
            </div>
        );
    }
}