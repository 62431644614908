import React from 'react';
import Chord from "../classes/Chord";

export default class ShapeRender extends React.Component {
    componentDidMount() {
        this.draw();
    }
    componentDidUpdate() {}

    draw() {

        if (this.props.type == 'line') {
            this.make_line(this.props.line_length, this.props.width);
        }
        else if (this.props.type == 'curve') {
            this.make_curve(this.props.curve_angle, this.props.curve_length, this.props.width);
        }
        else if (this.props.type == 'chicane') {
            this.make_chicane(this.props.chicane_length, this.props.width, this.props.chicane_offset);
        }
        else {

        }
    }

    make_curve (degrees, length, width) {
        let canvas = document.getElementById(this.props.canvas_id);

        const canvasDiagonal = Math.sqrt((canvas.width - 20)**2 + (canvas.height - 20)**2 );
        const widthDiagonal = Math.sqrt(2* (width**2) );
        const lengthRatio = (Number(length) + widthDiagonal*2) / canvasDiagonal;
        const newWidth = width / lengthRatio;

        const ctx = canvas.getContext("2d");
        ctx.transform(1, 0, 0, -1, 0, canvas.height);

        const chord = new Chord(
            { x1: 20 + newWidth/3, y1: 20 + newWidth/3 },
            { x2: canvas.width - 20 - newWidth/3, y2: canvas.height - 20 - newWidth/3 }
        );
        chord.draw(ctx);

        const radians = Math.radians(degrees);
        const circle = chord.getCircle(radians);
        const cp = circle.getCenterPoint();

        const end = Math.PI - circle.triangle4.A;
        const start = end - radians;

        ctx.beginPath();
        ctx.lineWidth = newWidth;
        ctx.strokeStyle = "grey";
        ctx.arc(cp.x, cp.y, chord.getRadius(radians), start, end);
        ctx.stroke();
    }

    make_line(length, width) {
        if (width > length) {
            throw "Width must be narrower than length";
        }

        let canvas = document.getElementById(this.props.canvas_id);

        const heightRatio = length / (canvas.height - 20);
        const newLength = length / heightRatio;
        const newWidth = width / heightRatio;

        const ctx = canvas.getContext("2d");
        ctx.transform(1, 0, 0, -1, 0, canvas.height);

        ctx.strokeStyle = "grey";
        ctx.lineWidth = newWidth;

        ctx.beginPath();
        ctx.moveTo(canvas.width/2, 10);
        ctx.lineTo(canvas.width/2, 10 + newLength);
        ctx.stroke();
    }

    make_chicane (length, width, offset) {
        let canvas = document.getElementById(this.props.canvas_id);

        const heightRatio = length / (canvas.height - 20);
        const newLength = length / heightRatio;
        const newWidth = width / heightRatio;

        const ctx = canvas.getContext("2d");
        ctx.transform(1, 0, 0, -1, 0, canvas.height);

        ctx.strokeStyle = "grey";
        ctx.lineWidth = newWidth;

        const halfOffset = offset == 0 ? 0 : offset/2;

        ctx.beginPath();
        ctx.moveTo(canvas.width/2 - halfOffset, 10);
        ctx.bezierCurveTo(
            canvas.width/2 - halfOffset,
            canvas.height/2,
            canvas.width/2 + halfOffset,
            canvas.height/2,
            canvas.width/2 + halfOffset,
            10 + newLength
        );
        ctx.stroke();
    }

    render() {
        return (
            <div className="">
                <canvas id={this.props.canvas_id} height="120" width="120"></canvas>
            </div>
        );
    }
}