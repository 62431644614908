import { createRoot } from "react-dom/client";
import Tracktor from "./components/Tracktor";

Math.radians = function (degrees) {
    return (degrees * Math.PI) / 180;
};

Math.degrees = function (radians) {
    return (radians * 180) / Math.PI;
};


const root = createRoot( document.getElementById("tracktor") );
root.render(<Tracktor />);