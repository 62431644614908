import Triangle from "./Triangle.js";

export default class Circle {
    constructor(chord, radians) {
        this.chord = chord;

        let triangle2 = new Triangle();
        triangle2.a = chord.getHeight(radians);
        triangle2.b = chord.getLength() / 2;
        triangle2.c = chord.getRadius(radians);
        triangle2.C = Math.radians(90);
        triangle2.A = Math.PI - radians / 2 - triangle2.C;
        triangle2.B = Math.radians(90) - triangle2.A;
        this.triangle2 = triangle2;
        // console.log('Triangle 2 - A', triangle2.A, Math.degrees(triangle2.A) );

        let triangle3 = new Triangle();
        triangle3.A = triangle2.A - chord.triangle1.A;
        triangle3.B = radians / 2;
        triangle3.C = Math.radians(180) - triangle3.A - triangle3.B;
        triangle3.c = triangle2.c;
        triangle3.b = (Math.sin(triangle3.B) * triangle3.c) / Math.sin(triangle3.C);
        this.triangle3 = triangle3;
        // console.log('Triangle 3 - A', triangle3.A, Math.degrees(triangle3.A) );

        let triangle4 = new Triangle();
        triangle4.C = Math.radians(90);
        triangle4.B = Math.radians(90) - triangle3.A;
        triangle4.A = Math.radians(90) - triangle4.B;
        triangle4.c = triangle3.c;
        triangle4.b = triangle4.c * Math.sin(triangle4.B);
        triangle4.a = Math.sqrt(triangle4.c ** 2 - triangle4.b ** 2);
        this.triangle4 = triangle4;
        // console.log('Triangle 4 - B', triangle4.B, Math.degrees(triangle4.B) );
        // console.log ('Slope: ', triangle4.b, triangle4.a);

        let triangle5 = new Triangle();
        triangle5.c = chord.getLength(radians) / 2;
        triangle5.C = Math.radians(90);
        triangle5.A = triangle2.B;
        triangle5.B = Math.radians(90) - triangle5.A;
        triangle5.a = (Math.sin(triangle5.A) * triangle5.c) / Math.sin(triangle5.C);
        triangle5.b = (Math.sin(triangle5.B) * triangle5.c) / Math.sin(triangle5.C);
        this.triangle5 = triangle5;
        // console.log("Triangle 5 - A", triangle5.A, Math.degrees(triangle5.A));

        let triangle6 = new Triangle();
        triangle6.C = Math.radians(90);
        triangle6.B = Math.radians(90) - triangle5.A;
        triangle6.A = Math.radians(90) - triangle6.B;
        triangle6.b = triangle3.b - triangle5.a;

        triangle6.c = (Math.sin(triangle6.C) * triangle6.b) / Math.sin(triangle6.B);
        // console.log(triangle6.c, chord.getHeight(radians));

        // if triangle has longer Y than X
        // or if X2 is smaller than X1
        // the radial offset is "north" from Y1
        // otherwise it is "south" so multiply by -1
        let directionY = 1;

        if (chord.x2 > chord.x1 || chord.triangle1.a > chord.triangle1.b) {
            directionY = 1;
        } else {
            directionY = -1;
            // console.log("SWAP #1");
        }

        if (chord.getHeight(radians) > triangle6.c) {
            directionY = directionY * -1;
            // console.log("SWAP #2");
        }

        this.centerPoint = {
            x: chord.x1 + triangle4.b,
            y: chord.y1 + directionY * triangle4.a
        };
    }

    draw(ctx, color) {
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = 0.5;
        ctx.moveTo(this.chord.x1, this.chord.y1);
        ctx.lineTo(this.centerPoint.x, this.chord.y1);
        ctx.lineTo(this.centerPoint.x, this.centerPoint.y);
        ctx.lineTo(this.chord.x1, this.chord.y1);
        ctx.stroke();

        // triangle5
        ctx.beginPath();
        ctx.strokeStyle = color;
        ctx.lineWidth = 0.5;
        ctx.moveTo(this.chord.x1, this.chord.y1);
        ctx.lineTo(this.chord.x1 + this.triangle5.a, this.chord.y1);
        ctx.lineTo(
            this.chord.x1 + this.triangle5.a,
            this.chord.y1 + this.triangle5.b
        );
        ctx.lineTo(this.chord.x1, this.chord.y1);
        ctx.stroke();
    }

    getCenterPoint() {
        return this.centerPoint;
    }
}