import React from 'react';
import {make_id} from "../make_id";

import ShapeRender from "./ShapeRender";

export default class Shape extends React.Component {
    constructor(props) {
        super(props);
        this.state = {...props}

        delete this.state['handlers'];

        this.onChange = this.onChange.bind(this);
        this.onClickRemove = this.onClickRemove.bind(this);
    }

    onChange(e) {
        this.setState(prevState =>{
            let state = prevState;
            state[e.target.name] = e.target.value;
            state.render_id = prevState.id + '_' + make_id(6);
            return state;
        }, () => {
            this.props.handlers.onChange(this.state.id, this.state);
        });
    }

    onClickRemove(e) {
        this.props.handlers.onRemove(this.state.id, this.state);
    }

    render() {
        return (
            <form>
                <div className="field is-grouped">
                    <div className="field">
                        <ShapeRender key={this.state.render_id} {...this.state} />
                    </div>
                    <div className="field">
                        <div className="field">
                            <label className="label">Shape</label>
                            <div className="control">
                                <label className="radio">
                                    <input checked={this.state.type=='line'} type="radio" value="line" name="type" onChange={this.onChange} />
                                    Line
                                </label>
                                <label className="radio">
                                    <input checked={this.state.type=='curve'} type="radio" value="curve" name="type" onChange={this.onChange} />
                                    Curve
                                </label>
                                <label className="radio">
                                    <input checked={this.state.type=='chicane'} type="radio" value="chicane" name="type" onChange={this.onChange} />
                                    Chicane
                                </label>
                            </div>
                        </div>

                        {this.state.type == 'line' &&
                            <div className="field">
                                <label className="label">Line Length (mm)</label>
                                <div className="control">
                                    <input onChange={this.onChange} name="line_length" min="1" max="400" className="input" type="number"
                                           value={this.state.line_length}/>
                                </div>
                            </div>
                        }
                        {this.state.type == 'curve' &&
                            <div className="">
                                <div className="field">
                                    <label className="label">Curve Length (mm)</label>
                                    <div className="control">
                                        <input onChange={this.onChange} name="curve_length" min="1" max="400" className="input" type="number"
                                               value={this.state.curve_length}/>
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Curve Angle (deg)</label>
                                    <div className="control">
                                        <input onChange={this.onChange} name="curve_angle" min="1" max="180" className="input" type="number"
                                               value={this.state.curve_angle}/>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.type == 'chicane' &&
                            <div className="">
                                <div className="field">
                                    <label className="label">Chicane Length (mm)</label>
                                    <div className="control">
                                        <input onChange={this.onChange} name="chicane_length" min="1" max="400" className="input" type="number"
                                               value={this.state.chicane_length}/>
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label">Chicane Offset (mm)</label>
                                    <div className="control">
                                        <input onChange={this.onChange} name="chicane_offset" min="-100" max="100" className="input" type="number"
                                               value={this.state.chicane_offset} />
                                    </div>
                                </div>
                            </div>
                        }

                        <div className="field">
                            <label className="label">Quantity</label>
                            <div className="control">
                                <input type="number" min="0" className="input" name="quantity" onChange={this.onChange}
                                       value={this.state.quantity} />
                            </div>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control">
                            <button className="button is-danger is-outlined is-small" onClick={() => this.onClickRemove()}>X</button>
                        </div>
                    </div>

                </div>
            </form>
        );
    }
}