import React from 'react';
import {make_id} from "../make_id";

import Shape from "./Shape";
import Circuit from "./Circuit";

export default class Tracktor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openWorkerRequests: 0,
            shapes: [],
            circuits: []
        };

        try {
            const localShapes = window.localStorage.getItem('shapes');
            if (localShapes) {
                this.state.shapes = JSON.parse(localShapes);
            }
        } catch (e) {}

        this.worker = new Worker(new URL('../Worker.js', import.meta.url), {type: 'module'});
        this.worker.onmessage = this.onCircuits.bind(this);
    }

    componentDidMount () {
        if (this.state.shapes.length) {
            this.calcCircuits()
        }
    }

    onClickAdd () {
        const key = make_id(6)
        const shape = {
            key: key,
            id: key,
            quantity: 1,
            render_id: 'render_' + key,
            canvas_id: 'canvas_' + key,
            width: 5,
            type: 'line',
            line_length: 25,
            curve_length: 25,
            curve_angle: 90,
            chicane_length: 25,
            chicane_offset: 10
        };
        shape.signature = this.getShapeSignature(shape);
        this.setState(prevState => {
            return { shapes: [ ...prevState.shapes, shape ]}
        }, this.onShapesChange.bind(this) );
    }

    onShapesChange () {
        window.localStorage.setItem('shapes', JSON.stringify(this.state.shapes));
        this.calcCircuits()
    }

    calcCircuits () {
        this.setState(prevState => {
            return {
                openWorkerRequests: prevState.openWorkerRequests + 1
            }
        });

        this.worker.postMessage([this.state.shapes]);
    }

    onCircuits (e) {
        this.setState(prevState => {
            return {
                circuits: e.data,
                openWorkerRequests: prevState.openWorkerRequests - 1
            }
        });
    }

    getShapeSignature(shapeState) {
        switch (shapeState.type) {
            case 'curve':
                return 'curve/' + shapeState.curve_length + '/' + shapeState.curve_angle;
            case 'line':
                return 'line/' + shapeState.line_length;
            case 'chicane':
                return 'chicane/' + shapeState.chicane_length + '/' + shapeState.chicane_offset;
            default:
                throw "Unknown shape type";
        }
    }

    onChildChange (key, state) {
        this.setState(prevState => {
                state.signature = this.getShapeSignature(state);
                return {
                    shapes: prevState.shapes.map(
                        item => item.key == key ? {...item, ...state} : item
                    )
                }
            },
            this.onShapesChange.bind(this)
        );
    }
    onChildRemove (key, state) {
        this.setState(prevState => {
                return {
                    shapes: prevState.shapes.filter(
                        item => item.key != key
                    )
                }
            },
            this.onShapesChange.bind(this)
        );
    }

    onClickNuke () {
        this.setState(() => {
                return {
                    shapes: []
                }
            },
            this.onShapesChange.bind(this)
        );
    }

    render() {
        return (
            <section className="section">
                <h1 className="title">Tracktor</h1>
                <h2 className="subtitle">A simple track generator.</h2>

                <nav className="level">
                    <div className="level-left">
                        <p className="level-item">
                            <button className="button is-primary" onClick={() => this.onClickAdd()}>+ Add Shape</button>
                        </p>
                        <p className="level-item">
                            {this.state.shapes.length > 0 &&
                                <button className="button is-danger" onClick={() => this.onClickNuke()}>Nuke</button>
                            }
                        </p>
                    </div>

                </nav>

                <div className="container" id="library">
                    {this.state.shapes.map((shape, i) => (
                        <Shape
                            key={i}
                            {...shape}
                            handlers={{
                                onChange: this.onChildChange.bind(this),
                                onRemove: this.onChildRemove.bind(this)
                            }}
                        />
                    ))}
                </div>

                {this.state.openWorkerRequests > 0 &&
                    <div className="container" id="loading">
                        <progress className="progress is-small is-primary" max="100">15%</progress>
                    </div>
                }

                <div className="container" id="circuits">
                    {this.state.circuits.map((circuit, i) => (
                        <Circuit key={i} {...circuit} />
                    ))}
                </div>

            </section>
        );
    }
}