import Triangle from "./Triangle.js";
import Circle from "./Circle.js";

export default class Chord {
    constructor({ x1, y1 }, { x2, y2 }) {
        this.x1 = x1;
        this.y1 = y1;
        this.x2 = x2;
        this.y2 = y2;

        this.triangle1 = new Triangle();
        this.triangle1.a = Math.abs(this.y2 - this.y1 );
        this.triangle1.b = Math.abs(this.x2 - this.x1 );

        this.triangle1.c = Math.sqrt(this.triangle1.a ** 2 + this.triangle1.b ** 2);
        this.triangle1.A = Math.asin(this.triangle1.a / this.triangle1.c);
    }
    getLength() {
        return this.triangle1.c;
    }
    draw(ctx) {
        ctx.beginPath();
        ctx.moveTo(this.x1, this.y1);
        ctx.lineTo(this.x2, this.y2);
        ctx.stroke();
    }
    getRadius(radians) {
        //Divide the central angle in radians by 2 and perform the sine function on it.
        //Divide the chord length by double the result of step 1
        return Math.abs( this.getLength() / (2 * Math.sin(radians / 2)));
    }
    getHeight(radians) {
        const a = this.getRadius(radians);
        const b = this.getLength() / 2;
        return Math.sqrt(a > b ? a ** 2 - b ** 2 : b ** 2 - a ** 2);
    }

    getCircle(radians) {
        return new Circle(this, radians);
    }

    getCenterPoint(radians, direction) {
        const middlePoint = {
            x: this.x1 + (this.x2 - this.x1) / 2,
            y: this.y1 + (this.y2 - this.y1) / 2,
        }

        const height = Number(this.getHeight(radians));

        const angleAdjustment = direction + radians;
        const triangleAngle = radians < 0 ? -1 * this.triangle1.A : this.triangle1.A;
        const angle = Number(angleAdjustment + triangleAngle) % Math.radians(360);

        const cp = {
            x: Math.round(Number(middlePoint.x) + (height * Math.cos(angle))),
            y: Math.round(Number(middlePoint.y) + (height * Math.sin(angle)))
        }

        // console.log(i,
        //     Math.degrees(direction),
        //     Math.round(Math.degrees(radians)),
        //     Math.degrees(angleAdjustment),
        //     Math.round(Math.degrees(angle)),
        //     Math.round(Math.degrees(triangleAngle)),
        //     height,
        //     Math.cos(angle),
        //     Math.sin(angle),
        //     cp
        // )

        return cp;
    }
}